import { render, staticRenderFns } from "./Dialog.vue?vue&type=template&id=73de7400&scoped=true&"
import script from "./Dialog.vue?vue&type=script&lang=js&"
export * from "./Dialog.vue?vue&type=script&lang=js&"
import style0 from "./Dialog.vue?vue&type=style&index=0&id=73de7400&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "73de7400",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TextOverflow: require('/builds/oho/oho-web-app/components/TextOverflow.vue').default,CaseStatusLabel: require('/builds/oho/oho-web-app/components/Case/StatusLabel.vue').default,TimeFormated: require('/builds/oho/oho-web-app/components/TimeFormated.vue').default,TimeDiff: require('/builds/oho/oho-web-app/components/TimeDiff.vue').default,TimeCounter: require('/builds/oho/oho-web-app/components/TimeCounter.vue').default,TagListView: require('/builds/oho/oho-web-app/components/Tag/ListView.vue').default,ProfileImage: require('/builds/oho/oho-web-app/components/ProfileImage.vue').default,ContactName: require('/builds/oho/oho-web-app/components/ContactName.vue').default,MemberProfileImage: require('/builds/oho/oho-web-app/components/Member/ProfileImage.vue').default,TeamTag: require('/builds/oho/oho-web-app/components/Team/Tag.vue').default,NameCardMember: require('/builds/oho/oho-web-app/components/NameCard/Member.vue').default,CaseActivity: require('/builds/oho/oho-web-app/components/Case/Activity.vue').default,MediaLibraryTable: require('/builds/oho/oho-web-app/components/MediaLibrary/Table.vue').default,ModalExpandFile: require('/builds/oho/oho-web-app/components/ModalExpandFile.vue').default})
