
export default {
  props: {
    tags: {
      type: Array,
      default: () => [],
    },
    group: {
      type: String,
      default: "tag",
    },
    tag_color: {
      type: String,
      default: "",
    },
    show_first: {
      type: Boolean,
      default: false,
    },
    placeholder_input: {
      type: String,
      default: "ค้นหาหรือสร้างแท็กใหม่",
    },
    select_btn_text: {
      type: String,
      default: "เพิ่มแท็ก",
    },
    add_btn: {
      type: String,
      default: "text",
    },
    edit_tag: {
      type: Boolean,
      default: true,
    },
    btn_add_tag_last: {
      type: Boolean,
      default: false,
    },
    addTagFn: {
      type: Function,
      default: () => {},
    },
    createTagFn: {
      type: Function,
      default: () => {},
    },
    removeTagFn: {
      type: Function,
      default: () => {},
    },
    data_tracking_id: {
      type: String,
      default: "",
    },
    is_show_open_popup_button: {
      type: Boolean,
      default: false,
    },
    is_show_tag_button: {
      type: Boolean,
      default: false,
    },
    is_show_untag_button: {
      type: Boolean,
      default: false,
    },
    is_show_create_button: {
      type: Boolean,
      default: false,
    },
    is_show_update_button: {
      type: Boolean,
      default: false,
    },
    is_show_remove_button: {
      type: Boolean,
      default: false,
    },
  },
  methods: {},
}
